<template>
  <div class="wrapper">
    <router-view />
  </div>
</template>

<script>
import {mapActions} from "pinia";
import {useAuthStore} from "@/stores/auth";

export default {
  methods: {
    ...mapActions(useAuthStore, ['setUserData']),
  },
  created() {
    const user = localStorage.getItem("user")
    if (!user) {
      this.$router.push({name: "login"})
    }
    this.setUserData(JSON.parse(user))
  }
}
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';
</style>
