import {createPinia, defineStore} from 'pinia'

export const useAuthStore = defineStore('AuthStore', {
  state: () => {
    return {
      user: {}
    }
  },
  getters: {
    getApiKey: (state) => state.user.api_key,
  },
  actions: {
    deleteUserData() {
      this.user = ''
    },
    setUserData(data) {
      this.user = data
    }
  }
})

const store = createPinia()

export default store
