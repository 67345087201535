import {createRouter, createWebHistory} from "vue-router";

const routes = [
  {
    path: "/",
    name: "template",
    component: () => import("../views/PageTemplate"),
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () => import("../components/DashboardVue.vue")
      },
      {
        path: "/resources",
        name: "resources",
        component: () => import("../components/Resources/ResourcesList.vue")
      },
      {
        path: "/resource/edit/:id",
        name: "resource-edit",
        component: () => import("../components/Resources/ResourceEdit.vue")
      },
      {
        path: "/favorites",
        name: "favorites",
        component: () => import("../components/Favorites/FavoritesList.vue")
      },
      {
        path: "/tags",
        name: "tags",
        component: () => import("../components/Tags/TagsList.vue")
      },
      {
        path: "/users",
        name: "users",
        component: () => import("../components/Admin/Users/UsersList.vue")
      },
      {
        path: "/links",
        name: "links",
        component: () => import("../components/LinkChannels/LinksList.vue")
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginPage.vue")
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
});
// remove modal backdrop if one exists
router.beforeEach((to, from, next) => {
  let modalBackground = document.querySelector('.modal-backdrop')
  if (modalBackground) {
    modalBackground.remove()
  }
  next()
})

export default router;
