import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from "./router";
import VueSweetalert2 from 'vue-sweetalert2';

import './assets/main.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'admin-lte/dist/js/adminlte.min'
import 'jquery/dist/jquery.min'

import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/css/regular.css'
import '@fortawesome/fontawesome-free/css/solid.css'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(VueSweetalert2)
app.mount('#app')
